import axios from 'axios'
import Utils from '@/services/Utils'
import moment from 'moment'

export default {
  hasInternet(callback) {
    if (navigator && !navigator.onLine) {
      callback({
        success: false,
        message:
          window.strings && window.strings['no_internet'] ? window.strings['no_internet'] : 'Por favor verifica a tua conexão à internet',
      })
      return false
    } else {
      return true
    }
  },
  // Chat 1.0
  getAuthorization: function () {
    const user = JSON.parse(process.env.VUE_APP_EMAIL).chat
    const password = JSON.parse(process.env.VUE_APP_PASSWORD).chat
    return 'Basic ' + window.btoa(user + ':' + password)
  },
  validateChatToken: function (callback) {
    const currentDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss')
    if (
      !axios.defaults.chatAuthorizationToken ||
      !axios.defaults.chatAuthorizationExpireDate ||
      (axios.defaults.chatAuthorizationExpireDate && axios.defaults.chatAuthorizationExpireDate <= currentDate)
    ) {
      axios
        .post(
          axios.defaults.chatApiUrl + 'login?lng=' + window.language,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: this.getAuthorization(),
            },
          },
        )
        .then((response) => {
          if (response.status === 200 && response.data && response.data.data && response.data.data.id) {
            axios.defaults.chatAuthorizationExpireDate = moment.utc().add(1, 'hours').format('YYYY-MM-DDTHH:mm:ss')
            axios.defaults.chatAuthorizationToken = 'Bearer ' + response.data.data.id
          } else {
            axios.defaults.chatAuthorizationExpireDate = null
            axios.defaults.chatAuthorizationToken = null
          }
          callback()
        })
        .catch(() => {
          axios.defaults.chatAuthorizationExpireDate = null
          axios.defaults.chatAuthorizationToken = null
          callback()
        })
    } else {
      callback()
    }
  },
  getChatWorkLog(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(
          axios.defaults.chatApiUrl +
            'work_log?query=[' +
            (data.employeeId ? '{"f":"employee_id","q":0,"v":' + data.employeeId + ',"n":1},' : '') +
            '{"f":"employee_pt_id","q":0,"v":' +
            (data.employeePtId ? data.employeePtId : axios.defaults.ptId) +
            ',"n":1},{"f":"half_hour","q":5,"v":0,"n":1},{"f":"record_utc","d":1,"q":5,"v":"' +
            data.date +
            ' 00:00:00","tz":' +
            window.diffUtcHours +
            ',"n":1},{"f":"record_utc","d":1,"q":4,"v":"' +
            data.date +
            ' 23:59:59","tz":' +
            window.diffUtcHours +
            ',"n":0}]&website=1&lng=' +
            window.language,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatMessagesWorkLog(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(
          axios.defaults.chatApiUrl +
            'work_log/messages?employee_id=' +
            data.employeeId +
            '&employee_pt_id=' +
            data.employeePtId +
            '&start_date=' +
            data.date +
            '&end_date=' +
            data.date +
            '&website=1&lng=' +
            window.language +
            '&tz=' +
            window.diffUtcHours,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatWorkLogSearch(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      if (!data.endDate) {
        data.endDate = data.startDate
      }
      axios
        .get(
          axios.defaults.chatApiUrl +
            'work_log/search?employee_pt_id=' +
            axios.defaults.ptId +
            (data.clientId ? '&client_id=' + data.clientId : '') +
            '&start_date=' +
            data.startDate +
            '&end_date=' +
            data.endDate +
            '&website=1&lng=' +
            window.language +
            '&tz=' +
            window.diffUtcHours,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatNewMessagesCount(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(axios.defaults.chatApiUrl + 'message/count?user_id=' + data + '&details=true&lng=' + window.language, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatMessage(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(
          axios.defaults.chatApiUrl +
            'message?filter={"chat_id":' +
            data.chat_id +
            ',"id":' +
            data.id +
            '}&read=false&lng=' +
            window.language,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatRoomsIds(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(axios.defaults.chatApiUrl + 'chat_group?search={"user_id":' + JSON.stringify(data.ids) + '}&lng=' + window.language, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatRoomsList(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(
          axios.defaults.chatApiUrl +
            'chat?users_ids=' +
            JSON.stringify(data.ids) +
            (data.fields ? '&fields=' + JSON.stringify(data.fields) : '') +
            '&users=true&lng=' +
            window.language,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatRoomsLastMsg(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(
          axios.defaults.chatApiUrl +
            'chat?search={"id":' +
            JSON.stringify(data.ids) +
            '}&fields=["id","last_msg_utc"]&lng=' +
            window.language,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  sendChatMessages(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .post(axios.defaults.chatApiUrl + 'message?array=true&lng=' + window.language, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getChatUserByEmail(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(axios.defaults.chatApiUrl + 'user?filter={"email":"' + data + '"}&fields=["id"]', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  newChatUser(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .post(axios.defaults.chatApiUrl + 'user', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  updateChatUser(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      const id = data.id
      delete data.id
      axios
        .patch(axios.defaults.chatApiUrl + 'user?id=' + id, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  newChat(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .post(axios.defaults.chatApiUrl + 'chat', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  updateChat(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      const id = data.id
      delete data.id
      axios
        .patch(axios.defaults.chatApiUrl + 'chat?id=' + id, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: axios.defaults.chatAuthorizationToken,
          },
        })
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  getStatisticMessagesAvg(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }

    this.validateChatToken(function () {
      axios
        .get(
          axios.defaults.chatApiUrl +
            'statistic/messages-avg?start_date=' +
            data.start_date +
            '&end_date=' +
            data.end_date +
            '&tz=' +
            window.diffUtcHours +
            '&website=1&lng=' +
            window.language,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: axios.defaults.chatAuthorizationToken,
            },
          },
        )
        .then((response) => {
          Utils.processResponse(response, callback)
        })
        .catch((response) => {
          Utils.processResponse(response, callback)
        })
    })
  },
  // Chat 2.0
  loginChatV2(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }
    axios
      .post(axios.defaults.chat2ApiUrl + 'token/', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        callback({
          success: true,
          data: response.data,
        })
      })
      .catch((response) => {
        const responseData = Utils.processResponse(response)
        callback({
          success: false,
          message: responseData && responseData.message ? responseData.message : '',
        })
      })
  },
  getChatV2Users(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }
    axios
      .get(axios.defaults.chat2ApiUrl + 'user/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + window.chatLoginToken.access,
        },
        params: {
          page: 1,
          page_size: 50,
          id__in: data.ids.join(','),
          serializer_option: 'user_with_last_message',
        },
      })
      .then((response) => {
        callback({
          success: true,
          data: response.data,
        })
      })
      .catch((response) => {
        const responseData = Utils.processResponse(response)
        callback({
          success: false,
          message: responseData && responseData.message ? responseData.message : '',
        })
      })
  },
  sendChatV2Messages(data, callback) {
    if (!this.hasInternet(callback)) {
      return false
    }
    axios
      .post(axios.defaults.chat2ApiUrl + 'chat_message_bulk_create/', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + window.chatLoginToken.access,
        },
      })
      .then((response) => {
        callback({
          success: true,
          data: response.data,
        })
      })
      .catch((response) => {
        const responseData = Utils.processResponse(response)
        callback({
          success: false,
          message: responseData && responseData.message ? responseData.message : '',
        })
      })
  },
}
